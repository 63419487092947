<template>
  <loading-dialog :loading="$apollo.loading">
    <!-- general -->
    <v-card class="pa-2">
      <v-card-title class="pb-0">Geral:</v-card-title>
      <v-row class="switches" no-gutters>
        <v-col cols="4">
          <v-card class="mb-2 input-box" flat>
            <v-card-text>
              <label class="subheading"><v-icon class="mb-1 mr-2">mdi-hexagon-slice-3</v-icon> Status:</label>
              <v-select v-model="status" :items="statusList" :disabled="!editable" style="margin: 10px 100px 0 0" outlined />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card class="mb-2 mt-4 input-box" flat>
            <v-card-text>
              <label class="subheading"><v-icon class="mb-1 mr-2">mdi-alert-circle-outline</v-icon> Ativo:</label>
              <v-switch v-model="active" :disabled="!editable" class="mr-auto" value />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card class="mb-2 input-box" flat>
            <v-card-text>
              <label class="subheading">CNPJ:</label>
              <v-text-field v-if="cnpj" v-model="cnpj[0]" disabled outlined />
              <v-btn v-if="cnpj && cnpj.length > 1" style="margin-top: 20px" color="primary" block outlined @click="cnpjModal = true"
                ><v-icon class="mr-1">mdi-plus</v-icon> Mostrar mais</v-btn
              >
              <v-btn v-else style="margin-top: 20px" color="primary" block @click="cnpjModal = true"
                ><v-icon class="mr-1">mdi-plus</v-icon> Adicionar CNPJ</v-btn
              >
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card class="mb-2 input-box" flat>
            <v-card-text>
              <label class="subheading">Nome:</label>
              <v-text-field v-model="name" :disabled="!editable" outlined />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col>
          <v-card class="mb-2 input-box" flat>
            <v-card-text>
              <label class="subheading">Id Externo:</label>
              <v-text-field v-model="id_external" :disabled="!editable" outlined />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col>
          <v-card class="mb-2 input-box" flat>
            <v-card-text>
              <label class="subheading">Data de Fidelidade:</label>
              <date-picker v-model="loyalty_program_start_date" :disabled="!editable" :date.sync="loyalty_program_start_date" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card class="mb-2 input-box" flat>
            <v-card-text>
              <label class="subheading">ERP:</label>
              <v-text-field v-model="erp.name" disabled outlined />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col>
          <v-card class="mb-2 input-box" flat>
            <v-card-text>
              <label class="subheading">Acrônimo ERP:</label>
              <v-text-field v-model="erp.acronym" disabled outlined />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col>
          <v-card flat>
            <v-card-text>
              <label class="subheading"
                ><v-icon type="button" tag="a" style="text-decoration:none;" :href="`https://airflow-cluster.mercafacil.com/tree?dag_id=dag_${db_name}`"
                  >mdi-database</v-icon
                >
                DB:</label
              >
              <v-text-field v-model="db_name" disabled outlined />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-card class="mb-2 input-box" flat>
            <v-card-text>
              <label class="subheading">Varejista:</label>
              <base-auto-complete
                v-model="selectedRetailer"
                :items="retailersData"
                item-text="formated_name"
                item-value="_id"
                :disabled="client_id === null ? !editable : true"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card class="mb-2 input-box" flat>
            <v-card-text>
              <label class="subheading">Conexão:</label>
              <v-text-field v-model="connection" disabled outlined />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <btn-card-actions :right="true" :editable.sync="editable" @save="saveManager" @cancel="cancelAndRestore" />
      <v-dialog v-model="cnpjModal" max-width="500" persistent transition="dialog-bottom-transition">
        <v-card tile>
          <v-toolbar flat dark color="primary">
            <!-- close btn -->
            <v-btn icon @click="cnpjModal = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <!-- title -->
            <v-toolbar-title>CNPJs</v-toolbar-title>
            <v-spacer></v-spacer>

            <!-- add param -->
            <v-toolbar-items>
              <v-btn dark text @click="addCnpj('')">
                Adicionar
              </v-btn>
            </v-toolbar-items>

            <!-- save -->
            <v-toolbar-items>
              <v-btn dark text @click="saveEdit">
                Salvar
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <!-- add param modal -->
          <v-card flat>
            <v-card-text class="pb-0">
              <v-card-actions class="pt-0 px-0 pb-4">
                <v-btn v-if="!editCnpj" block color="primary" @click="editCnpj = true">Editar</v-btn>
                <v-btn v-else block color="error" @click="editCnpj = false">Cancelar</v-btn>
              </v-card-actions>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-for="(field, index) in cnpj" :key="index" v-model="cnpj[index]" :disabled="!editCnpj" label="Nº" :value="field" outlined>
                    <template v-if="editCnpj" v-slot:append-outer>
                      <v-icon color="error" @click="deleteCnpj(index)">mdi-delete</v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card>
      </v-dialog>
      <change-dialog :dialog.sync="changesDialog" :message.sync="changesMessage" @proceed="saveEdit" />
    </v-card>

    <!-- modules -->
    <account-modules :account.sync="fullAccount" :account-id="accountId" @refresh="refresh" />

    <!-- pdv vouchers -->
    <account-channels-pdv
      v-if="fullAccount && fullAccount.integration_type !== 'backoffice'"
      :account.sync="fullAccount"
      :account-id="accountId"
      @refresh="refresh"
    />

    <!-- json -->
    <v-expansion-panels v-if="fullAccount">
      <v-expansion-panel class="mt-3">
        <v-expansion-panel-header>JSON</v-expansion-panel-header>
        <v-expansion-panel-content>
          <div style="max-height: 300px; overflow: auto;">
            <vue-json-pretty :deep="5" :data.sync="fullAccount" highlight-mouseover-node show-line />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </loading-dialog>
</template>

<script>
import { QUERY_GET_ACCOUNT, MUTATION_EDIT_ACCOUNT } from '@/modules/accounts/graphql'
import { QUERY_GET_RETAILERS_PAGINATED } from '@/modules/retailers/graphql'
import 'vue-json-pretty/lib/styles.css'
import BaseAutoComplete from '../../../../components/atomic-components/atoms/BaseAutoComplete.vue'
import cnpj from '../../../../filters/cnpj'

export default {
  components: {
    LoadingDialog: () => import('@/components/base/LoadingDialog'),
    DatePicker: () => import('@/components/calendar/DatePicker'),
    ChangeDialog: () => import('@/components/base/ChangeDialog'),
    BtnCardActions: () => import('@/components/base/BtnCardActions'),
    VueJsonPretty: () => import('vue-json-pretty'),
    AccountModules: () => import('@/modules/accounts/components/edit/AccountModules'),
    AccountChannelsPdv: () => import('@/modules/accounts/components/edit/AccountChannelsPdv'),
    BaseAutoComplete
  },
  data: () => ({
    accountId: '',
    name: '',
    acronym: '',
    db_name: '',
    active: null,
    id_external: '',
    loyalty_program_start_date: '',
    status: '',
    statusList: [
      'Concluído',
      'Implantando',
      'Suporte impossível',
      'Teste',
      'Churn',
      'Churn temporário',
      'Em ajuste',
      'Plataforma Antiga',
      'Aguardando lançamento'
    ],
    fullAccount: null,
    cnpj: [],
    erp: {
      name: '',
      acronym: ''
    },
    dialog: false,
    cnpjModal: false,
    editCnpj: false,
    editable: false,
    changesDialog: false,
    changesMessage: '',
    flags: {
      ecommerce: {
        active: false
      }
    },
    client_id: '',
    selectedRetailer: '',
    retailersData: [],
    connection: ''
  }),
  computed: {
    isDeployment() {
      return this.status === 'Implantando' || this.status === 'Teste'
    }
  },
  apollo: {
    adminAccount: {
      query: QUERY_GET_ACCOUNT,
      fetchPolicy: 'network-only',
      variables() {
        return { _id: this.accountId }
      },
      skip() {
        return !this.accountId
      },
      update({ adminAccount }) {
        if (!adminAccount) return {}

        this.fullAccount = adminAccount
        this.name = adminAccount?.name
        this.acronym = adminAccount?.acronym
        this.db_name = adminAccount?.db_name
        this.active = adminAccount?.active
        this.id_external = +adminAccount?.id_external
        this.erp.name = adminAccount?.erp?.name
        this.erp.acronym = adminAccount?.erp?.acronym
        this.loyalty_program_start_date = adminAccount?.loyalty_program_start_date
        this.cnpj = adminAccount?.cnpj || null
        this.setStatus(adminAccount?.status_account)
        this.flags.ecommerce.active = adminAccount?.flags?.ecommerce?.active
        this.client_id = adminAccount?.client_id || null
        this.selectedRetailer = adminAccount?.client_id || null

        if (adminAccount.channels.database?.host?.includes('homolog')) {
          this.connection = 'Homologação'
        } else if (adminAccount.channels.database?.host?.includes('smb')) {
          this.connection = 'SMB'
        } else if (adminAccount.channels.database?.host?.includes('ka')) {
          this.connection = 'Key Account'
        } else if (adminAccount.channels.database?.host?.includes('raf')) {
          this.connection = 'RAF'
        } else if (adminAccount.channels.database?.host?.includes('tenant')) {
          this.connection = 'Tenant'
        }
      }
    },
    retailers: {
      query: QUERY_GET_RETAILERS_PAGINATED,
      fetchPolicy: 'network-only',
      variables() {
        return Object.assign(
          {},
          { filters: {} },
          {
            pagination: {
              page: 1,
              pageSize: 9999,
              search: '',
              sort: ['_id'],
              sortDirection: 'ASC'
            }
          }
        )
      },
      update({ clients: { count, data } }) {
        const result = data.map(item => {
          return {
            _id: item._id,
            formated_name: `[${item.id_client}] ${item.fantasy_name} | ${cnpj(item.cnpj)}`
          }
        })
        this.retailersData = result
      },
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['crm']
        }
      }
    }
  },
  mounted() {
    this.accountId = this.$route.params.id
  },
  methods: {
    changeStatus() {
      const status = {
        deploying: this.status === 'Implantando',
        done: this.status === 'Concluído',
        impossible_support: this.status === 'Suporte impossível',
        test: this.status === 'Teste',
        churn: this.status === 'Churn',
        temporary_churn: this.status === 'Churn temporário',
        adjusting: this.status === 'Em ajuste',
        deprecated: this.status === 'Plataforma Antiga',
        waiting_release: this.status === 'Aguardando lançamento'
      }
      return status
    },
    setStatus(status) {
      if (status?.deploying) return (this.status = 'Implantando')
      if (status?.done) return (this.status = 'Concluído')
      if (status?.churn) return (this.status = 'Churn')
      if (status?.impossible_support) return (this.status = 'Suporte impossível')
      if (status?.test) return (this.status = 'Teste')
      if (status?.temporary_churn) return (this.status = 'Churn temporário')
      if (status?.adjusting) return (this.status = 'Em ajuste')
      if (status?.deprecated) return (this.status = 'Plataforma Antiga')
      if (status?.waiting_release) return (this.status = 'Aguardando lançamento')
      else this.status = ''
    },
    async saveEdit() {
      let variables = {
        _id: this.accountId,
        name: this.name,
        id_external: +this.id_external,
        loyalty_program_start_date: this.loyalty_program_start_date,
        cnpj: this.cnpj,
        active: this.active,
        status: this.changeStatus()
      }

      if (this.client_id === null && this.selectedRetailer !== null) {
        variables.client_id = this.selectedRetailer
        this.client_id = this.selectedRetailer
      }

      try {
        this.$setLogMessage(this.changesMessage)
        await this.$apollo.mutate({
          mutation: MUTATION_EDIT_ACCOUNT,
          variables
        })
        this.editable = false
        this.editCnpj = false
        this.$alert({
          alert_message: 'Alterações salvas com sucesso!',
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
      } catch (e) {
        console.log(e.message)
        this.$alert({
          alert_message: 'Falha ao editar conta',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    },
    addCnpj(field) {
      this.cnpj.push(field)
      this.editCnpj = true
    },
    async deleteCnpj(index) {
      this.cnpj.splice(index, 1)
    },
    cancelAndRestore() {
      this.editable = false
      this.$apollo.queries.adminAccount.refetch()
    },
    saveManager() {
      if (this.isDeployment) return this.saveEdit()
      this.changesDialog = true
    },
    async refresh() {
      return this.$apollo.queries.adminAccount.refetch()
    }
  }
}
</script>
<style lang="scss" scoped>
.switches {
  margin-top: 15px;
}
</style>
